<template>
  <div class="search">
    <div class="search-box">
      日期: <el-date-picker v-model="search.date" type="daterange"
                            range-separator="To"
                            start-placeholder="起始"
                            value-format="YYYYMMDD"
                            end-placeholder="结束"
    ></el-date-picker>
    </div>
    <div class="search-box">
      <el-button type="primary" size="medium" @click="getList">查询</el-button>
    </div>
  </div>
  <el-table :data="tableData">
    <el-table-column prop="date" label="日期"></el-table-column>
    <el-table-column prop="id" label="ID"></el-table-column>
    <el-table-column prop="name" label="名字"></el-table-column>
    <el-table-column prop="click_count" label="点击量"></el-table-column>
  </el-table>
  <page :pager="pager" @query="getList()" />
</template>

<script>
import httpClient from "@/config/httpClient";
import page from "@/components/page.vue";
export default {
  name: "ad_community",
  components: {
    page
  },
  data() {
    return {
      tableData:[],
      pager:{page:1,rows:20,total:0},
      search:{
        app_id:"",
        game_name:"",
        game_type:-1,
        status:-1,
      },
      types:[
        {type:1,name:"首页"}
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let param = this.search
      if (this.search.date && this.search.date.length === 2){
        param.bdate = this.search.date[0]
        param.edate = this.search.date[1]
      }
      param.page = this.pager.page -1
      param.row = this.pager.rows
      httpClient("staticBannerList").post(param).then((res) => {
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }
      })
    },
    getType(id){
      for(const t of this.types){
        if (t.type == id){
          return t.name
        }
      }
      return "未知分类:"+id
    },
  },
};
</script>